/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ydxFTPuZ .__0OPOGTiI {
  font-family: var(--font-family);
  font-weight: 600;
  text-align: center;
  color: var(--primary-color-black);
}
.ydxFTPuZ .__8BYO2EFV {
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 400;
  text-align: center;
  color: var(--primary-light-grey);
}
.ydxFTPuZ .rxGWhZ4q {
  background-color: var(--primary-color-white);
  padding: 2rem;
  box-sizing: border-box;
}
.ydxFTPuZ .rxGWhZ4q .__8251dw_m {
  margin-right: 1.25rem;
}
.ydxFTPuZ .rxGWhZ4q .Ha19HEiL .h4J3wxg8 {
  font-weight: 400;
  color: #17243e;
  font-family: var(--font-family);
}
.ydxFTPuZ .rxGWhZ4q .Ha19HEiL .ABrOz94H {
  font-weight: 400;
  text-align: right;
  color: #808696;
  margin-bottom: 40px;
  margin-top: 0.5rem;
}
.ydxFTPuZ .rxGWhZ4q .Ha19HEiL .ABrOz94H:before {
  content: "-";
  width: 3.125rem;
  border-bottom: 1px solid #808696;
  margin-right: 0.625rem;
  display: inline-block;
  color: white;
  position: relative;
  bottom: 10px;
}
.ydxFTPuZ .rxGWhZ4q .RG7ZouFv {
  height: 60px;
  width: 100%;
}
@media (min-width: 1200px) {
  .ydxFTPuZ {
    background: url('/img/img_bac.png') no-repeat center center;
    background-size: cover;
    padding: 3rem 0;
  }
  .ydxFTPuZ .ant-tabs > .ant-tabs-nav,
  .ydxFTPuZ .ant-tabs > div > .ant-tabs-nav {
    margin: 0 !important;
  }
  .ydxFTPuZ .ant-tabs-tab {
    padding: 0 !important;
  }
  .ydxFTPuZ .__0OPOGTiI {
    font-size: 28px;
  }
  .ydxFTPuZ .__8BYO2EFV {
    margin-bottom: 12px;
  }
  .ydxFTPuZ .rxGWhZ4q {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75rem;
    margin: 0 auto;
  }
  .ydxFTPuZ .rxGWhZ4q .__8251dw_m {
    width: 31.9375rem;
    height: 288px;
  }
  .ydxFTPuZ .rxGWhZ4q .ZdW5xyVt {
    height: 288px;
    overflow: hidden;
    position: relative;
    transition: all 0ms ease-in 0s;
  }
  .ydxFTPuZ .rxGWhZ4q .ZdW5xyVt .Ha19HEiL {
    overflow: hidden;
    position: relative;
  }
}
@media (max-width: 1199px) and (min-width: 0px) {
  .ydxFTPuZ {
    background-color: var(--primary-color-bg);
    padding: 1.5rem var(--mobile-all-margin) 1.5rem var(--mobile-all-margin);
  }
  .ydxFTPuZ .__0OPOGTiI {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .ydxFTPuZ .__8BYO2EFV {
    display: none;
  }
  .ydxFTPuZ .rxGWhZ4q .__8251dw_m {
    margin-bottom: 20px;
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 1199px) and (min-width: 500px) {
  .WqZwEw8f .s2ME7tBc {
    flex: 1;
    display: flex;
  }
  .WqZwEw8f .s2ME7tBc .aDOj5eoz {
    width: calc(50% - 0.625rem);
    margin-right: 1.25rem;
  }
  .WqZwEw8f .s2ME7tBc .aDOj5eoz:last-child {
    margin-right: 0;
  }
}

